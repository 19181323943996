.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.Header-Button{
  color:#FFFFFF !important
}

.Header-Button-Active{
  color:#FFCC00 !important
}

.App-header {
 
}
.About-Image{
  width:75%
}
.Slider{
  height: 10px;
}
.About-Container{
  background: '#282828';
  color: '#fff';

}
.Carousel{
  height:100px;
}

.App-Content{
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background-image: url(../public/background.png);
  height:100%;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-text{
  height: 60%;
    display: grid;
    align-content: center;
}

a:link{
  color:#FFCC00;
}

a:visited{
  color:#7003D6;
}

.row{
  width: 100%;
  align-items: center;

}
.sign-desc{
  max-width:50%;
  margin: 0 auto;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(28,28,28,0.4);
}
.Footer{
  background-color: #282c34;
  width: 100%;
  color: #fff;
}
.email-input {
  width: 30%;
  height: 50px;
  font-size: 18px;
  padding-left: 12px;
  padding-right: 12px;
  border-radius: 8px;
  background-color: #333;
  color: #fff;
  border: 2px solid #444;
}

.subscribe-button {
  width: 150px;
  height: 50px;
  font-size: 18px;
  border-radius: 8px;
  background-color: #444;
  color: #fff;
  border: 2px solid #555;
  cursor: pointer;
}


.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
